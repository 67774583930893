import { FCM_ENABLE_SUBSCRIPTIONS_FOR } from '@change/config/fcm/configs';

import { createFcmContext } from 'src/app/shared/contexts/fcm';
import { FCM_MEMBERSHIP_IOS_AMOUNTS_ENABLED_FOR, FCM_SUBSCRIPTION_CONFIGURATION } from 'src/app/shared/fcm/configs';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmContext: MembershipAwarenessFormFcmContext,
	FcmProvider: MembershipAwarenessFormFcmContextProvider,
	useFcm: useMembershipAwarenessFormFcm,
	useAsyncFcm: useAsyncMembershipAwarenessFormFcm,
	prefetchFcm: prefetchMembershipAwarenessFormFcm,
} = createFcmContext({
	name: 'MembershipAwarenessFormFcmContext',
	dataProperty: 'formFcm',
	configs: {
		membershipEnabled: FCM_ENABLE_SUBSCRIPTIONS_FOR,
		subscriptionConfig: FCM_SUBSCRIPTION_CONFIGURATION,
		iosAmountsEnabled: FCM_MEMBERSHIP_IOS_AMOUNTS_ENABLED_FOR,
	},
	prefetch: true,
});

export type MembershipAwarenessFormFcmState = ReturnType<typeof useAsyncMembershipAwarenessFormFcm>;
export type MembershipAwarenessFormFcm = LoadedStateData<MembershipAwarenessFormFcmState>;
