/* eslint-disable max-lines-per-function */
import { useCallback, useMemo } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { useNavigate } from '@change/core/react/router';
import { useTracking } from '@change/core/react/tracking';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { useIOSAmounts } from 'src/app/shared/hooks/membership';

import {
	useMembershipAwarenessFormData,
	useMembershipAwarenessFormFcm,
	useMembershipAwarenessFormFcmExperiments,
} from '../../../../formContext';
import type { Props } from '../../../../shared/types';
import { getSubscriptionAmount } from '../../../../shared/utils/getSubscriptionAmount';

type Result = ModelHookResult<
	{
		buttonAmounts: ReadonlyArray<{ amount: number; amountLocalized: string }>;
		selectedAmount: { amount: number; amountLocalized: string };
		buttonText?: string;
		displayPayWithText: boolean;
	},
	{
		onClick: (amount?: number) => void;
	}
>;

export function useAmountSelector({ sourceLocation, slug }: Props): Result {
	const track = useTracking();
	const navigate = useNavigate();
	const { amountToBaseUnits, translate } = useI18n();
	const {
		subscriptionsAmounts: buttonAmounts,
		subscriptionsDefaultAmount: defaultAmount,
		currency,
	} = useMembershipAwarenessFormData();
	const { subscriptionConfig, iosAmountsEnabled } = useMembershipAwarenessFormFcm();
	const utilityContext = useUtilityContext();
	const { defaultAmountExperiment, membershipAmountsButtonOptionsExperiment } =
		useMembershipAwarenessFormFcmExperiments();

	const iosAmounts = useIOSAmounts();

	const iosAmountExperimentResult: ReturnType<typeof iosAmounts> = useMemo(() => {
		if (iosAmountsEnabled) {
			return iosAmounts();
		}
		return { enabled: false };
	}, [iosAmounts, iosAmountsEnabled]);

	const buttonAmountOptions = useMemo(() => {
		if (iosAmountExperimentResult.enabled) {
			return buttonAmounts.map((amountPair) => {
				return getSubscriptionAmount(
					{
						amount: Math.ceil(amountPair.amount * iosAmountExperimentResult.ratio),
						currency,
						currencyDisplay: 'narrowSymbol',
						amountInBaseUnits: false,
					},
					utilityContext,
				);
			});
		}
		const { variation, fcm } = membershipAmountsButtonOptionsExperiment;
		if (variation === 'control') return buttonAmounts;

		if (fcm.config && (variation === 'variant_1' || variation === 'variant_2')) {
			return fcm.config[variation].amounts.map((amount) =>
				getSubscriptionAmount(
					{
						amount,
						currency,
						currencyDisplay: 'narrowSymbol',
						amountInBaseUnits: false,
					},
					utilityContext,
				),
			);
		}
		return buttonAmounts;
	}, [iosAmountExperimentResult, membershipAmountsButtonOptionsExperiment, buttonAmounts, currency, utilityContext]);

	const selectedAmount = useMemo(() => {
		const { variation, fcm } = membershipAmountsButtonOptionsExperiment;
		const setDefaultAmount =
			fcm.config && (variation === 'variant_1' || variation === 'variant_2')
				? getSubscriptionAmount(
						{
							amount: fcm.config[variation].defaultAmount,
							currency,
							currencyDisplay: 'narrowSymbol',
							amountInBaseUnits: false,
						},
						utilityContext,
					)
				: defaultAmount;
		return defaultAmountExperiment.variation === 'variant' || iosAmountExperimentResult.enabled
			? buttonAmountOptions[2]
			: setDefaultAmount;
	}, [
		iosAmountExperimentResult,
		membershipAmountsButtonOptionsExperiment,
		currency,
		utilityContext,
		defaultAmount,
		defaultAmountExperiment.variation,
		buttonAmountOptions,
	]);

	const onClick = useCallback(
		(amount?: number) => {
			void track(`${sourceLocation}_membership_ask_click`);
			if (subscriptionConfig?.thirdPartySubscriptionService) {
				void track('membership_ask_click_third_party_subscription_service');
				navigate(subscriptionConfig?.thirdPartySubscriptionService);
				return;
			}
			const path = slug ? `/p/${encodeURIComponent(slug)}/psf/membership` : '/member/join';
			navigate(
				`${path}?source_location=${sourceLocation}&amount=${amountToBaseUnits(amount ?? selectedAmount.amount, currency)}`,
			);
		},
		[track, sourceLocation, slug, navigate, amountToBaseUnits, selectedAmount.amount, currency, subscriptionConfig],
	);

	return {
		data: {
			selectedAmount,
			buttonAmounts: buttonAmountOptions,
			buttonText: translate('corgi.membership.awareness.cta'),
			displayPayWithText: !subscriptionConfig?.thirdPartySubscriptionService,
		},
		actions: {
			onClick,
		},
	};
}
